<template>
  <div>
    <a-modal
      :visible="visible"
      title="补充物流信息"
      :confirmLoading="loading"
      :destroyOnClose="true"
      :maskClosable="false"
      @cancel="cancel"
      @ok="confirm"
    >
      <div>
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
          <a-form-model-item prop="delivery_method" label="配送方式">
            <a-input v-model="form.delivery_method" />
          </a-form-model-item>
          <a-form-model-item prop="express" label="承运公司">
            <a-input v-model="form.express" />
          </a-form-model-item>
          <a-form-model-item prop="logistics_number" label="物流单号">
            <a-input v-model="form.logistics_number" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { deliveryOrderSupplementInfo } from "@/api/stockOut.js";
import rules from "./rules.js";

export default {
  props: ["visible", "form"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      rules,
      loading: false,
    };
  },
  methods: {
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          deliveryOrderSupplementInfo(this.form)
            .then((data) => {
              this.$message.success("补充成功");
              this.$emit("update", data);
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
  },
};
</script>

<style scoped></style>
